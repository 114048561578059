/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Layout from "components/Layout";
import { H1, SubHeader } from "components/AuthLayouts";
import BlogPostCardGrid from "components/BlogPostCardGrid";

export default function Blog({
  pageContext: {
    blogPage: { title, description, brailleTextImage },
    posts,
  },
}) {
  return (
    <Layout
      title={title}
      brailleTextImage={brailleTextImage}
      description={description}
    >
      <H1>{title}</H1>
      <SubHeader style={{ marginBottom: 41, width: [null, null, "50%"] }}>
        {description}
      </SubHeader>
      <BlogPostCardGrid posts={posts} />
    </Layout>
  );
}
